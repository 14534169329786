import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer, Flip } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { accountService } from '../../services'
import MaskedInput from 'react-text-mask'
import Swal from 'sweetalert2'
import cookie from 'js-cookie'
import { userLogin, userUpdate } from '../../store/actions/cartActions';
import Image from 'next/image';
const Moment = require('moment')


const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success ml-1',
      cancelButton: 'btn btn-danger mr-1'
    },
    buttonsStyling: false
  })


const AccountSettings = (props) => {

    
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const scopedValueInitialState = false;
    const [ scopedValue, setScopedValue ] = useState(scopedValueInitialState) 

    const [, updateState] = React.useState();
    const [ loadingRequest, setLoadingRequest ] = useState(true)
    const [ request, setRequest ] = useState(true)
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const initialValues = {};

    const validationSchema = Yup.object().shape({
        email: Yup.string().max(40, 'Too long'),
        address_line_1: Yup.string().max(100, 'Too long'),
        address_line_2: Yup.string().max(100, 'Too long'),
        address_line_3: Yup.string().max(100, 'Too long'),
        address_zip: Yup.string().max(20, 'Too long'),
        address_unit: Yup.string().max(10, 'Too long'),
        birthdate: Yup.date(),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters'),
        newPassword: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .when('password', (password, schema) => {
                if (password) return schema.required('Your New Password is required');
            }),
        confirmPassword: Yup.string()
            .when('newPassword', (newPassword, schema) => {
                if (newPassword) return schema.required('Confirm Password is required');
            })
            .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    });



    const getCurrentInfo = async () => {

        try{
            setLoadingRequest(true) 
            let _user = await accountService.getMe()
            if(_user.request && _user.request.name){
                setRequest(_user.request)
            } else {
               
                if(cookie.get('NAME_REQUEST_SENT')==='true'){
                    dispatch(userUpdate({
                        ...user,
                        name: _user.name
                    }))
                    cookie.set('NAME_REQUEST_SENT', false)
                }
                setRequest(false)
            }
            setLoadingRequest(false)
        } catch (er){
            console.log(er)
        }

    }

    useEffect(() => {
        
        getCurrentInfo()
    }, [props])


    const changeName = (field) => {
        
        Swal.fire({
            title: 'Enter your name',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Send',
            showLoaderOnConfirm: true,
            preConfirm: (name) => {
            
                let request = {
                    ...user.request,
                    name,
                }
         
                if(user.name){
                    cookie.set('NAME_REQUEST_SENT', true)
                }
                return updateUser(request)
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `Request sent`,
                })
            }
        })
    }

    const updateUser = async (request) => {
      
        accountService.updateOwn({request})
            .then( async response => {
               
                let _user = await accountService.getMe()
               
               
                await dispatch(userLogin({...user, ..._user}))
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.message,
                })
            })
        
    }

    const cancelRequest = async () => {
        swalWithBootstrapButtons.queue([{
            title: 'Cancel request',
            text: "Click the green button below to continue with your cancellation request, or hit the red to go back.",
           
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No, I will wait!',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: () => { 
              return accountService.updateOwn({request:{}}).then(data => {
                Swal.fire(
                  'Done!',
                  'This request has been cancelled!',
                  'success'
                )
                cookie.set('NAME_REQUEST_SENT', false)
                getCurrentInfo()
              })
              .catch(error => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                  })
              })
            } 
          }])
    }


    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        
        if(fields.birthdate){
            fields.birthdate = Moment(fields.birthdate).toISOString();
        }
        let _fields = {
            [scopedValue]: fields[scopedValue]
        }
        if(fields.address_unit){
            _fields.address_unit = fields.address_unit
        }
        if(fields.address_line_1){
            _fields.address_line_1 = fields.address_line_1
        }
        if(fields.address_line_2){
            _fields.address_line_2 = fields.address_line_2
        }
        if(fields.address_line_3){
            _fields.address_line_3 = fields.address_line_3
        }
        if(fields.address_zip){
            _fields.address_zip = fields.address_zip
        }
        if(fields.newPassword){
            _fields.newPassword = fields.newPassword
        }
        if(fields.confirmPassword){
            _fields.confirmPassword = fields.confirmPassword
        }
       
        accountService.updateOwn(_fields)
            .then((res) => {
                accountService.getMe(user.token)
                    .then((userData) => {
                        props.dispatchUser(userData)
                        forceUpdate()
                    })
                toast.success('Profile updated!', {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setScopedValue(false);
                setSubmitting(false);
            })
            .catch(error => {
                setSubmitting(false);
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
    }

    const formButtons = (isSubmitting, resetForm) => (
        <div className="form-buttons">
            <button onClick={()=>{resetForm(), setScopedValue(false)}} className="default-btn">
                    Cancel
            </button>
            <button type="submit" disabled={isSubmitting} className="default-btn">
                    {isSubmitting ? "Loading..." : "Save"}
            </button>
        </div>
    )

    return (
        <>
        
                <ToastContainer
                        transition={Flip} 
                />
                <div className="modal-innter-content">
                    <div className="modal-body">
                        <div className="sidebar-about-content">
                            <h3>&nbsp;@{user.nickname} Settings</h3>
                            <div className="id_container rounded d-flex mb-4">
            
                 <div style={{    cursor: 'pointer', padding:'10px',
    width: '40%'}}>

                 
           {
                props.user.picture_id ? (
                    <Image 
                        alt={`User ID Card Picture.`}
                        src={props.user.picture_id.replace('.jpg','').replace('.png','')}
                           width={200}
                           height={200}
                           // layout='raw'
                           style={{width:'100%'}}
                        quality='75'
                      
                            
                       
                    /> 
                ) : (
                    <Image 
                   
                        alt='Card Placeholder Picture.' 
                      
                 width={200}
                 height={200}
                 // layout='raw'
                     
                       loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                       src={'/static/images/id_ex.png'} 
                       style={{width:'100%'}}
                    ></Image>
                )
            }  
            </div>                 
            <div className="actions">
                <p className="account-info-label">ID Photo</p>
                <label for="picture_id" className="account-info-label-input badge badge-primary"><i className="bx bx-pencil">&nbsp;</i>{!props.user.picture ? "Add" : "UPDATE"}</label>
                <input name="picture_id" id="picture_id" className="inputfile" onChange={(e)=>props.handleImageUpload(e)} type="file"/><br />
                <small>Make ordering easier</small>
            </div>
        </div>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting,handleReset, handleChange, setFieldTouched, setFieldValue, resetForm , values}) => (
                <Form className="login-form">
                       
                            <ul className={"account-info-list"}>
                               
                                {(scopedValue == false || scopedValue==="name") ? 
                                    <li>
                                        <div className={`account-single-info`}>
                                            <div>
                                                <p className="account-info-label">Legal Name</p>
                                                <p className="account-info-data">{user.name}</p>
                                            </div>
                                            {!scopedValue && <div className="actions">
                                                <a onClick={()=>setScopedValue("name")} href="#"><i className="bx bx-pencil">&nbsp;</i>{!user.email ? "Add" : "Update"}</a>
                                            </div>}
                                        </div>

                                        {
                                            scopedValue=="name" && <div className="form-group">
                                                <br></br>
                                                <Field name="name" type="text" placeholder={`${!user.name ? 'Input name' : 'Input new name'}`} className={'form-controla' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                {
                                                    formButtons(isSubmitting, resetForm)
                                                }
                                            </div>
                                        }

                                    </li>
                                : ""}
                                
                                {(scopedValue == false || scopedValue==="email") ? 
                                    <li>
                                        <div className="account-single-info">
                                            <div>
                                                <p className="account-info-label">Email</p>
                                                <p className="account-info-data">{user.email}</p>
                                            </div>
                                            {!scopedValue && <div className="actions">
                                                <a onClick={()=>setScopedValue("email")} href="#"><i className="bx bx-pencil">&nbsp;</i>{!user.email ? "Add" : "Update"}</a>
                                            </div>}
                                        </div>

                                        {
                                            scopedValue=="email" && <div className="form-group">
                                                <br></br>
                                                <Field name="email" type="text" placeholder="Input new email" className={'form-controla' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                {
                                                    formButtons(isSubmitting, resetForm)
                                                }
                                            </div>
                                        }

                                    </li>
                                : ""}
                                
                                {(scopedValue == false || scopedValue==="birthdate") ? 
                                    <li>
                                        <div className="account-single-info">
                                            <div>
                                                <p className="account-info-label">Birthday</p>
                                                {
                                                    user.birthdate && <p className="account-info-data">{(Moment(user.birthdate).format("MMMM Do YYYY"))}</p>
                                                }
                                                
                                            </div>
                                            {!scopedValue && <div className="actions">
                                                <a onClick={()=>setScopedValue("birthdate")} href="#"><i className="bx bx-pencil">&nbsp;</i>{!user.birthdate ? "Add" : "Update"}</a>
                                            </div>} 
                                        </div>

                                        {
                                            scopedValue=="birthdate" && <div className="form-group">
                                                <br></br>
                                                <Field name="birthdate" type="date" placeholder="Input new birthdate" className={'form-controla' + (errors.birthdate && touched.birthdate ? ' is-invalid' : '')} />
                                                <ErrorMessage name="birthdate" component="div" className="invalid-feedback" />
                                                {
                                                    formButtons(isSubmitting, resetForm)
                                                }
                                            </div>
                                        }

                                    </li>
                                : ""}

                           
                                {(scopedValue == false || scopedValue==="phone") ? 
                                    <li>
                                        <div className="account-single-info">
                                            <div>
                                                <p className="account-info-label">Phone number</p>
                                                <p className="account-info-data">{user.phone}</p>
                                            </div>
                                            {!scopedValue && <div className="actions">
                                                <a onClick={()=>setScopedValue("phone")} href="#"><i className="bx bx-pencil">&nbsp;</i>{!user.phone ? "Add" : "Update"}</a>
                                            </div>}
                                        </div>

                                        {
                                            scopedValue=="phone" && <div className="form-group">
                                                <br></br>
                                                <div className="form-group">
                                    <label className="form-label">Phone number</label>
                                    <Field  name="phone"
										render={({ field, form }) => (
											<MaskedInput
                                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                              className={'form-controla' + (errors.phone && touched.phone ? ' is-invalid' : '')} 
                                
                                              {...field}
                                              guide={true}
                                            
                                              onBlur={setFieldTouched}
                                              onChange={(e) => {
                                                handleChange(e)
                                                const value = e.target.value || '';
                                                setFieldValue('phone', value);
                                              }}
                                            />
										)}
										type="text"  
									/>
                                    <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                </div>
                                                <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                                {
                                                    formButtons(isSubmitting, resetForm)
                                                }
                                            </div>
                                        }

                                    </li>
                                : ""}
                           
                                {/* <li>
                                    <p>ID Photo</p>
                                    <p>{user.email}</p>
                                </li> */}
                            
                            {(scopedValue == false || scopedValue==="address") ? 
                                    <li>
                                        <div className="account-single-info">
                                            <div>
                                                <p className="account-info-label">Delivery Address</p>
                                                <p className="account-info-data no-margin-v">{`${ user.address_unit ? user.address_unit : '' }`}</p>
                                                <p className="account-info-data no-margin-v">{`${ user.address_line_1 ? user.address_line_1 : '' }`}</p>
                                                <p className="account-info-data no-margin-v">{`${ user.address_line_2 ? user.address_line_2 : '' }`}</p>
                                                <p className="account-info-data no-margin-v">{`${ user.address_line_3 ? user.address_line_3 : '' }`}</p>
                                                <p className="account-info-data no-margin-v">{`${ user.address_zip ? user.address_zip : '' }`}</p>
                                            </div>
                                            {!scopedValue && <div className="actions">
                                                <a onClick={()=>setScopedValue("address")} href="#"><i className="bx bx-pencil">&nbsp;</i>{(!user.address_line_1 && !user.address_line_2 && !user.address_line_3 && !user.address_zip)? "Add" : "Update"}</a>
                                            </div>}
                                        </div>

                                        {
                                            scopedValue=="address" && <div className="form-group">
                                                <br></br>
                                                
                                                <Field name="address_line_1" type="text" placeholder="Street address" className={'form-controla' + (errors.address_line_1 && touched.address_line_1 ? ' is-invalid' : '')} />
                                                <ErrorMessage name="address_line_1" component="div" className="invalid-feedback" />
                                                
                                                <br></br>
                                                <Field name="address_unit" type="text" placeholder="Unit #" className={'form-controla' + (errors.address_unit && touched.address_unit ? ' is-invalid' : '')} />
                                                <ErrorMessage name="address_unit" component="div" className="invalid-feedback" />
                                                <br></br>
                                                <Field name="address_line_2" type="text" placeholder="City" className={'form-controla' + (errors.address_line_2 && touched.address_line_2 ? ' is-invalid' : '')} />
                                                <ErrorMessage name="address_line_2" component="div" className="invalid-feedback" />
                                                <br></br>
                                                <Field name="address_line_3" type="text" placeholder="State" className={'form-controla' + (errors.address_line_3 && touched.address_line_3 ? ' is-invalid' : '')} />
                                                <ErrorMessage name="address_line_3" component="div" className="invalid-feedback" />
                                                <br></br>
                                                <Field name="address_zip" type="text" placeholder="Postal code" className={'form-controla' + (errors.address_zip && touched.address_zip ? ' is-invalid' : '')} />
                                                <ErrorMessage name="address_zip" component="div" className="invalid-feedback" />
                                                {
                                                    formButtons(isSubmitting, resetForm)
                                                }
                                            </div>
                                        }

                                    </li>
                                : ""}

                                {(scopedValue == false || scopedValue==="password") ? 
                                    <li>
                                        <div className="account-single-info">
                                            <div>
                                                <p className="account-info-label">Password</p>
                                                <p className="account-info-data">***********</p>
                                            </div>
                                            {!scopedValue && <div className="actions">
                                                <a onClick={()=>setScopedValue("password")} href="#"><i className="bx bx-pencil">&nbsp;</i>{"UPDATE"}</a>
                                            </div>}
                                        </div>

                                        {
                                            scopedValue=="password" && 
                                            <><br></br>
                                                <div className="form-group">
                                                    <Field name="password" type="password" placeholder="Input current password" className={'form-controla' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field name="newPassword" type="password" placeholder="Input new password" className={'form-controla' + (errors.newPassword && touched.newPassword ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field name="confirmPassword" type="password" placeholder="Confirm new password" className={'form-controla' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                                </div>
                                                {formButtons(isSubmitting, resetForm)}
                                            </>
                                        }

                                    </li>
                                : ""}
                            </ul>
                            </Form>
            )}
        </Formik>
                        </div>
                    </div>
                    <button type="button" className="close" onClick={props.closeModal}>
                        <span aria-hidden="true">
                            <i className='bx bx-x'></i>
                        </span>
                    </button>
                    <button title="Go back" type="button" className="back" onClick={() => {props.setMenuVisible()}}>
                        <span aria-hidden="true">
                            <i className='bx bx-left-arrow-alt'></i>
                        </span>
                    </button>
                </div>
                
        
        </>
    )


}

export default AccountSettings