import React, { Component, useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { connect, useSelector } from 'react-redux';
import { removeItem, getCart } from '../../store/actions/cartActions';
import { shoppingCartService  } from '../../services'
import ImageUrlFormatter from '../Common/ImageUrlFormatter'
import useCart from '../../utils/useCart'
import router, { useRouter } from 'next/router'
import dispensaryTypeChecker from '../../utils/dispensaryTypeChecker';
;
import Spinner from 'react-spinner-material';
import Image from 'next/image';


const hookClass = (props) => {

    const [ active, setActive ] = useState(false)
    const fetched_cart = useSelector(state => state.fetched_cart)
    const shouldCloseModal = async  (e) => {
        if(typeof e.target.className ==='string'){
            (e.target.className.includes('shoppingCartModal')) && closeThis()
        }
    }
    
    useEffect(() => {
        if(props.active === 'active'){
            setTimeout(() => {
                setActive(true)
            }, 10);
        }
    }, [props.active])

    const closeThis = () => {
        setActive(false);
        setTimeout(() => {
            props.onClick()
        }, 200)
    }

    const router = useRouter();

    const [ get, add, remove ] = useCart()

    // useEffect(() => {
    //     if(cookie.get('user')){
    //         get()
    //     }
    // }, [])
    
    return (
        <div onMouseDown={(e)=>shouldCloseModal(e)} className={`shoppingCartModal right ${active ? 'active' : ''}`} > 
            <div className="modal-innter-content">
                {
                    !fetched_cart ? (<div className='d-flex align-items-center justify-content-center mt-5'><Spinner /></div>) : (
                        <ShoppingCartModal 
                            router={router} 
                            addToCart={add}
                            getCartData={get}
                            removeFromCart={remove}
                            {...props}
                            onClick={closeThis}
                        />
                    )
                }
            </div>
        </div>
    )
    
    
}

class ShoppingCartModal extends Component {

    state = {
        modal: false, 
        total:0
    };

    handleTotal = () => {
        var total = 0
        this.props.products.map(product => {
            total += product.quantity * product._package.price;
        })
        this.setState({total})
    }

    totalQuantity = () => {
        var total = 0
        this.props.products.map(product => {
            total += parseInt(product.quantity, 10);
        })
        return total
    }

    handleRemove = async (id) => {
        await this.props.removeFromCart(id)
        // shoppingCartService.delete(id)
        //     .then(data => {
        //         console.log(id)
        //         this.props.removeItem(id)
        //         toast.success('Removed from cart', {
        //             position: "bottom-left",
        //             autoClose: 5000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true
        //         });
        //     })
        //     .catch( err => {
        //         toast.error(err, {
        //             position: "bottom-left",
        //             autoClose: 5000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true
        //         });
        //     })
        
    }

    closeModal = () => {
        this.props.onClick(this.state.modal);
    }


    

    // componentDidUpdate () {
    //     this.handleTotal()
    // }
    
    
    render() {
        let cartItems = this.props.products.length ? 
        (
            this.props.products.map((product, idx) => {
                return(
                    
                    <div className="products-cart-content" key={idx}>
                        <div className="products-cart">
   
                            <div className="products-image">
                                {
                                    (product.available) ? (
                                        <Link prefetch={false} href={`/brands/[brand]/products/[product]`} as={`/brands/${product.product.brand.slug}/products/${product.product.slug}`}>
                                            {
                                                product.product.picture && product.product.picture.length > 0 ? (
                                                    <Image 
                                                        alt={product.product.name+' picture.'}
                                                        src={product.product.picture[0].replace('.jpg','').replace('.png','')}
                                                        width={64}
                                                        height={64}
                                                        quality='75'
                                                    /> 
                                                ) : (
                                                    <Image 
                                                        alt='Default image placeholder.' 
                                                        width={64}
                                                        height={64}
                                                        loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                                        src={'/static/images/default-pic-small.png'} 
                                                    ></Image>
                                                )
                                            }
                                            

                                        </Link>
                                    ) : (
                                        <Image 
                                            alt='Default image placeholder.' 
                                            width={64}
                                            height={64}
                                            loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                            src={'/static/images/default-pic-small.png'} 
                                        ></Image>
                                    )
                                }
                            </div>
                                
                            <div className="products-content">
                                {
                                    product.available ? (
                                        <>
                                            {
                                                // (product.product.brand) ? (
                                                //     <h4>
                                                //         <Link prefetch={false} href={`/brands/[brand]/products/[product]`} as={`/brands/${product.product.brand.slug}/products/${product.product.slug}`}>
                                                //             <a>{product.product.name}</a>
                                                //         </Link>
                                                //     </h4>
                                                // ) : (

                                                    <h4>
                                                        <Link prefetch={false} href={`/[business]/[slug]/${this.props.embeeded ? 'embeeded' : 'menu'}/[product]`} as={`/${dispensaryTypeChecker(this.props.dispensary.type)}/${this.props.dispensary.slug}/${this.props.embeeded ? 'embeeded' : 'menu'}/${product.product.slug}`}>
                                                            {product.product.name}
                                                        </Link>
                                                    </h4>

                                                
                                                // )
                                            }
                                            {
                                                product.product && (product.product.isMedical && !product.product.isRecreational) && <span><span className='striking-text'>{'Medical Patients Only'}</span></span>
                                                
                                            }
                                            <div className='d-flex w-100 justify-content-between'>
                                            {
                                                !product.product.variant.stock ? (
                                                    <div className='products-price'>
                                                        <span className='text-danger'>Out of stock</span>
                                                    </div>
                                                ) : (
                                                    <span>
                                                        {
                                                            product.reward === true ? (
                                                                <div className="products-price">
                                                                    <span>{product.quantity}</span>
                                                                    <span>x</span>
                                                                    <span className="price">{product.product.variant.WPointsValue} Nugs</span>
                                                                </div>
                                                            ) : (
                                                                <div className="products-price">
                                                                    <span>{product.quantity}</span>
                                                                    <span>x</span>
                                                                    <span className="price">${product.product.variant.price}</span>
                                                                </div>
                                                            )
                                                        }
                                                    </span>
                                                )
                                            }<Link onClick={(e)=>{e.preventDefault();this.handleRemove(product._id)}} prefetch={false} href="#">
                                            
                                                <i className='bx bx-trash'></i>
                                       
                                        </Link>
                                        </div>
                                            
                                        </>
                                    ) : (
                                        <>
                                            <h4 className='text-muted'>{product.itemName}</h4>
                                            <div className="products-price text-danger">Product disabled</div>
                                            <div className='d-flex justify-content-end'>
                                                <a 
                                                    onClick={(e)=>{e.preventDefault();this.handleRemove(product._id)}}
                                                >
                                                    <i className='bx bx-trash'></i>
                                                </a>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                );
            })
        ) : (
            <div className="products-cart-content">
                <p>Empty</p>
            </div>
        )
        return (
            <React.Fragment>
                <button type="button" className="close" onClick={this.closeModal}>
                    <span aria-hidden="true">
                        <i className='bx bx-x'></i>
                    </span>
                </button>
                <div className="modal-body">
                    <h3>My Cart ({this.totalQuantity()})</h3>
                    {cartItems}
                    <div className="products-cart-subtotal">
                        <span>Subtotal in USD</span>
                        <span className="subtotal">${
                            this.props.medicalSubtotal + this.props.recreationalSubtotal
                        }</span>
                    </div> 
                    {
                        this.props.nugsSubtotal > 0 && <div className="products-cart-subtotal">
                            <span>Subtotal in Nugs</span>
                            <span className="subtotal">&#127811;{
                                this.props.nugsSubtotal
                            }</span>
                        </div> 
                    } 
                    {(this.props.medicalSubtotal + this.props.recreationalSubtotal >0 || this.props.nugsSubtotal>0) ? (
                        <>
                            
                            <div className="products-cart-btn">
                                {
                                    this.props.embeeded ? (
                                        <>
                                            <Link className="default-btn" onClick={()=>this.closeModal()} prefetch={false} href="/embeededCheckout">
                                                Proceed to Checkout
                                            </Link>
                                            <Link onClick={()=>this.closeModal()} className="optional-btn" prefetch={false} href="/embeededCart">
                                                View Cart
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <Link className="default-btn" onClick={()=>this.closeModal()} prefetch={false} href="/checkout">
                                                Proceed to Checkout
                                            </Link>
                                            <Link onClick={()=>this.closeModal()} className="optional-btn" prefetch={false} href="/cart">
                                                View Cart
                                            </Link>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    
                    ):(
                        <>
                            <center>
                                <p>You don't have any products in your cart</p>
                                <a className="optional-btn" onClick={()=>{
                                    this.closeModal()
                                    this.props.router.back()
                                }}>Continue Shopping</a>
                            </center>
                        </>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
        dispensary: state.dispensary,
        medicalSubtotal: state.medicalSubtotal,
        recreationalSubtotal: state.recreationalSubtotal,
        nugsSubtotal: state.nugsSubtotal,
        user: state.user,
        cart: state.cart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeItem: (id) => {dispatch(removeItem(id))},
        getCart: (cart) => {dispatch(getCart(cart))}
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(hookClass)