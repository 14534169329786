import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { shoppingCartService, localShoppingCartService } from '../services'
import { getCart, addToCart, removeItem, updateQuantity, resetCart, fetchedCart, updateModalStatus } from '../store/actions/cartActions';
import { ENTITY_CART, LOCAL_CART } from '../helpers/constTypes'

import isEmpty from './isEmpty';
import { useState } from 'react';


const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger mr-1'
    },
    buttonsStyling: false
})


const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})
    


const useCart = () => {
    const { user, dispensary } = useSelector(state => state)
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    

    const service = shoppingCartService
    const user_entity_cart = !isEmpty(user) ? undefined : localStorage.getItem(ENTITY_CART)


    const get = async (dispensaryId=user_entity_cart) => {
   
        let cartData = await service.get(dispensaryId)

        if(cartData){
            dispatch(getCart(cartData))
        }

        dispatch(fetchedCart(cartData))
        
        
    }


    const add = async (item) => {
        
        if(isEmpty(user)){
            await dispatch(updateModalStatus({login:true}))
        } else {
            const fields = {
                itemName: item.product.name,
                brandName: item.product.brand.name,
                originalPrice: item.product.variant.price || item.product.variant.WPointsValue,
                quantity: item.quantity,
                product: item.product._id,
                dispensary: item.dispensary,
                variant: item.product.variant._id,
                reward: item.reward
            }
            if(!isEmpty(dispensary) && item.dispensary!=dispensary._id){
                swalWithBootstrapButtons.fire({
                    title: 'Create a new cart',
                    text: "You currently have products in your cart from another menu, you could only add products from one menu at a time. Would you like to go back or proceed to create a new cart?",
                    // icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, create a new cart',
                    cancelButtonText: 'Go back',
                    reverseButtons: true
                }).then( async (result) => {
                    if (result.value) {
                        await dispatch(resetCart())
                        addItem(fields, item, {})
                    } 
                })
            } else {
                addItem(fields, item, dispensary)
            }

        }
       
        
    }

    const addItem = async (fields, item, dispensary) => {
       
        try {
            setLoading(true)
            let { _id } = await service.create(fields)
            if(isEmpty(dispensary)){
                await get(item.dispensary)
            } else {
            
                dispatch(addToCart({...item, _id, available: true}))
                
            
            }
            Toast.fire({
                // icon: 'success',
                title: 'Item added to cart'
            })
            setLoading(false)
        } catch(err) {
            swalWithBootstrapButtons.fire('Oooops', err.message, 'error')
        }

    }


    const remove = async(id) => {
        
        try {
            await service.delete(id)
            dispatch(removeItem(id))
        } catch (err){
            console.log(err)
        }
        
    }

    const update = async (_id, item) => {
        await service.update(_id, item)
        dispatch(updateQuantity(_id, item))
    }

    const sync = async () => {
       
        let localCart = localStorage.getItem(LOCAL_CART) ? JSON.parse(localStorage.getItem(LOCAL_CART)).map( x => {
            delete x._id
            return x
        }) : []
        if(localCart.length>0){
            try {
                await shoppingCartService.sync(localStorage.getItem(ENTITY_CART), {cartitems: localCart})
                await get()
                await localShoppingCartService.reset()
            } catch(err) {
                console.log(err)
            }
        }
    }

    return [
        get,
        add,
        remove,
        update,
        sync,
        loading
    ]

}

export default useCart


