import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import AccountSettings from '../Profile/AccountSettings'
import { userUpdate, userLogout } from '../../store/actions/cartActions';
import ImageUrlFormatter from '../Common/ImageUrlFormatter'
import { useSelector } from 'react-redux'
import { accountService } from '../../services'
import { toast, ToastContainer } from 'react-toastify';
import Image from 'next/image';

const hookClass = (props) => {

    const [ active, setActive ] = useState(false)

    useEffect(() => {
        if(props.active === 'active'){
            setTimeout(() => {
                setActive(true)
            }, 10);
        }
    }, [props.active])

    const closeThis = () => {
        setActive(false);
        setTimeout(() => {
            props.onClick()
        }, 500)
    }

    const shouldCloseModal = async  (e) => {
        if(typeof e.target.className ==='string'){
            (e.target.className.includes('sidebarModal')) && closeThis()
        }
    }
    const router = useRouter();
    const user = useSelector(state => state.user)

    return (
        <div onMouseDown={(e) => shouldCloseModal(e)} className={`sidebarModal right ${active ? 'active' : ''}`}> 
            <div className="modal-innter-content">
                <SidebarModal {...props} router={router} user={user} onClick={closeThis} />
            </div>
        </div>
    )
    
}

class SidebarModal extends Component {

    state = {
        modal: false,
        page: "accountMenu",
    };

    setMenuVisible = () => {
        this.setState({page:"accountMenu"})
    }

    fetchToDatabase = (fields) => {
        accountService.updateOwn(fields)
            .then( res => {
                accountService.getMe(this.props.user.token)
                    .then((userData) => {
                        this.dispatchUser(userData)
                    })

                toast.success('Profile updated!', {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
    }

    handleImageUpload = (e) => {
        const { name, files } = e.target
       
        const formData = new FormData();

         formData.append('file', files[0]);
         formData.append('upload_preset', 'spj28hqq');
         const options = {
             method: 'POST',
             body: formData,
         };

         return fetch('https://api.Cloudinary.com/v1_1/timj111/image/upload', options)
             .then(res => res.json())
             .then( res =>{
                 this.fetchToDatabase({[name]:res.secure_url})
             })
    }

    closeModal = () => {
        this.props.onClick(this.state.modal);
    }

    dispatchUser = (user) => {
        const newUser = Object.assign(this.props.user, user)
        this.props.userUpdate(newUser); 
    }

    handleLogout = () => {
        this.props.userLogout();
        this.closeModal();
        if(window.location.pathname.includes('/account')){
            this.props.router.push('/')
        } else {
            window.location.reload()
        }
    }

    escFunction = (e) => {
        if(e.keyCode === 27){
            this.closeModal()
        }
      }

    componentDidMount (){
     
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
      }


    myReviews = () => {
        return (
            <React.Fragment>
             
                   <ToastContainer />
                    <div className="modal-body">
                        <div className="sidebar-about-content">
                            <h3>&nbsp;My Reviews</h3>
                        </div>
                    </div>
                    <button type="button" className="close" onClick={this.closeModal}>
                        <span aria-hidden="true">
                            <i className='bx bx-x'></i>
                        </span>
                    </button>
                    <button title="Go back" type="button" className="back" onClick={() => {this.setState({page:"accountMenu"})}}>
                        <span aria-hidden="true">
                            <i className='bx bx-left-arrow-alt'></i>
                        </span>
                    </button>
          
            </React.Fragment>
        )
    }

    orderHistory = () => {
        return (
            <React.Fragment>
                <div className="modal-innter-content">
                   
                    <div className="modal-body">
                        <div className="sidebar-about-content">
                            <h3>&nbsp;Order History</h3>
                        </div>
                    </div>
                    <button type="button" className="close" onClick={this.closeModal}>
                        <span aria-hidden="true">
                            <i className='bx bx-x'></i>
                        </span>
                    </button>
                    <button title="Go back" type="button" className="back" onClick={() => {this.setState({page:"accountMenu"})}}>
                        <span aria-hidden="true">
                            <i className='bx bx-left-arrow-alt'></i>
                        </span>
                    </button>
                </div>
            </React.Fragment>
        )
    }

    




    accountMenu = () => {
        return (
            <React.Fragment>
                <button type="button" className="close" onClick={this.closeModal}>
                     <span aria-hidden="true">
                         <i className='bx bx-x'></i>
                     </span>
                </button>
                <div className="modal-body">
                    <div className="sidebar-about-content">
                        <div className="user-overview">
                            <div className="user-overview-picture">
                                <label for="picture" className="mb-0"> 
                                {
                                        this.props.user.picture ? (
                                            <Image 
                                                alt={`${this.props.user.name} profile picture.`}
                                                src={this.props.user.picture.replace('.jpg','').replace('.png','')}
                                                   
                                                quality='75'
                                               width={512}
                                               height={512}        
                                               
                                            /> 
                                        ) : (
                                            <Image 
                                           
                                                alt='Default image placeholder.' 
                                               // layout='raw'
                                               width={512}
                                               height={512}
                                                loader={({src, width, quality})=> {return `${src}`}}  
                                                src={`https://api.dicebear.com/5.x/avataaars-neutral/jpg?seed=${this.props.user._id}.jpg`} 
                                            ></Image>
                                        )
                                    }
                               
                                </label>
                                <input name="picture" id="picture" className="inputfile" onChange={(e)=>this.handleImageUpload(e)} type="file"/>
                                
                                
                            </div>
                            <div className="user-overview-info">
                                <div className="user-overview-username">
                                    @{this.props.user.nickname}
                                </div>
                                <div className="user-overview-email">
                                     {this.props.user.email}
                                </div>
                            </div>
                        </div>
                      
                        
                               
                                    <h3></h3>
                               
                            
                        <ul className="profile-options">
                            <li onClick={() => {this.setState({page:"accountSettings"})}} className="profile-option">
                                <i className="search-btn bx bx-cog"></i>
                                <p>Account settings</p>
                            </li>
                            <li onClick={() => {this.props.router.push("/account");this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bx-user"></i>
                                <p>Account overview</p>
                            </li>
                            {/* <li onClick={() => {this.setState({page:"accountMedia"})}} className="profile-option">
                                <i className="search-btn bx bx-image"></i>
                                <p>Account media</p>
                            </li> */}
                            {/* <li onClick={() => {this.props.router.push('/account/orders');this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bx-cart-alt"></i>
                                <p>My order history</p>
                            </li> */}
                            <li onClick={() => {this.props.router.push('/account/favorites');this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bx-star"></i>
                                <p>My Favorites</p>
                            </li>
                            <li onClick={() => {this.props.router.push('/account/points');this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bx-coin"></i>
                                <p>My Nugs Balance</p>
                            </li>
                            <li onClick={() => {this.props.router.push('/account/receipts');this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bx-receipt"></i>
                                <p>My Receipts</p>
                            </li>
                            <li onClick={() => {this.props.router.push('/account/orders');this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bxs-shopping-bags"></i>
                                <p>My Orders</p>
                            </li>
                            <li onClick={() => {this.props.router.push('/account/reviews');this.closeModal()}} className="profile-option">
                                <i className="search-btn bx bx-comment-detail"></i>
                                <p>My Reviews</p>
                            </li>
                            {/* <li onClick={() => {this.setState({page:"orderHistory"})}} className="profile-option">
                                <i className="search-btn bx bx-credit-card-front"></i>
                                <p>Order history</p>
                            </li>
                            <li onClick={() => {this.setState({page:"myReviews"})}} className="profile-option">
                                <i className="search-btn bx bx-message-square-detail"></i>
                                <p>My reviews</p>
                            </li> */}
                            <li className="profile-option" onClick={e => {e.preventDefault(); this.handleLogout();}}>
                                <i className="search-btn bx bx-log-out"></i>
                                <p>Log out</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }





    sidebarHandler = () => {
        switch (this.state.page) {
            case "accountMenu":
                return this.accountMenu();
                break;
        
            case "accountSettings":
                return <AccountSettings 
                    closeModal={this.closeModal} 
                    setMenuVisible = {this.setMenuVisible} 
                    user={this.props.user} 
                    dispatchUser={this.dispatchUser} 
                    handleImageUpload={this.handleImageUpload}
                />;
                break;

            case "orderHistory":
                return this.orderHistory();
                break;

            case "myReviews":
                return this.myReviews();
                break;
            
          

            default:
                break;
        }
    }

    render() {
        return (
            <React.Fragment>
                
                        {
                            this.sidebarHandler()
                        }
                  
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    const { user } = state;
    return {
        user:user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userUpdate: (id) => { dispatch(userUpdate(id)) },
        userLogout: () => {dispatch(userLogout())}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(hookClass);